<template>
	<v-layout fill-height column>
		<v-flex shrink>
			<v-layout row justify-end align-center>
				<v-tooltip bottom>
					<template v-slot:activator="{ on }">
						<v-btn flat round small color="primary" v-on="on" @click="addInformationRequest">
							<v-icon>add</v-icon>
							<span v-t="'client_workspace.task.add'" class="mx-2" />
						</v-btn>
					</template>
					<span v-t="'client_workspace.task.create.informationRequestTask'" />
				</v-tooltip>
				<v-tooltip bottom>
					<template v-slot:activator="{ on }">
						<v-btn flat round small color="primary" v-on="on" @click="showImportDialog = true">
							<v-icon>add</v-icon>
							<span v-t="'client_workspace.task.import.action'" class="mx-2" />
						</v-btn>
					</template>
					<span v-t="'client_workspace.task.import.informationRequestTask'" />
				</v-tooltip>
			</v-layout>
		</v-flex>
		<v-flex fill-height scroll-y>
			<v-list>
				<v-list-tile
					v-for="informationRequest in informationRequests"
					:key="informationRequest.id"
					@click="informationRequest.id ? select(informationRequest) : null"
				>
					<TasksManagerTask :value="informationRequest" class="pointer" />
				</v-list-tile>
			</v-list>
		</v-flex>
		<TasksManagerActionImportDialog v-if="showImportDialog" :show-import="showImportDialog" :parent-task="task" @close="showImportDialog = false" />
	</v-layout>
</template>

<script>
import TasksManagerModuleGuard from '@/mixins/ModulesGuards/TasksManager/TasksManagerModuleGuard'

export default {
	name: 'TasksManagerTaskDetailsInformationRequestList',
	components: {
		TasksManagerTask: () => ({
			component: import('@/components/TasksManager/TasksManagerTask')
		}),
		TasksManagerActionImportDialog: () => ({
			component: import('@/components/TasksManager/Actions/Import/TasksManagerActionImportDialog')
		})
	},
	mixins: [TasksManagerModuleGuard],
	props: {
		task: {
			required: true,
			type: Object
		}
	},
	data: function () {
		return {
			informationRequests: [],
			showImportDialog: false
		}
	},
	watch: {
		task: {
			deep: true,
			handler: function () {
				this.informationRequests = [...this.task.children.filter(this.service.isOfTaskInformationRequestType)]
			}
		}
	},
	created: function () {
		this.informationRequests = [...this.task.children.filter(this.service.isOfTaskInformationRequestType)]
	},
	methods: {
		select: function (infoRequest) {
			this.service.selectTask(infoRequest)
		},
		addInformationRequest: function () {
			const informationRequest = {
				title: '',
				description: '',
				note: '',
				vendor: this.task.vendor,
				parent: this.task,
				themes: this.task.themes.map(theme => theme.id),
				status: this.service.getFirstTaskStatus(),
				type: this.service.getTaskInformationRequestType()
			}
			this.informationRequests.unshift(informationRequest)
		}
	}
}
</script>
